<template>
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <Modal v-show="showModal" @closeModal="showModal = false" @confirmModal="confirmModal"/>
    <CardTable :tableBody="filteredTableBody">
      <div class="mb-3 row">
          <select v-model="merchantType" class="form-select me-2" style="margin-left: 13px; width: 200px !important" @change="getList">
            <option v-for="(item, index) in retailerList" :key="index" :value="item">{{item}}</option>
          </select>
          <select v-model="status" class="form-select me-2" style="width: 200px !important" @change="getList">
            <option v-for="(item, index) in statusList" :key="index" :value="item">{{item}}</option>
          </select>
          <span v-if="internalUser" class="ms-1 mt-2" style="width: 80px;">Sort by:</span>
          <select v-if="internalUser" v-model="sort" class="form-select" style="width: 200px !important" @change="getList">
            <option v-for="(item, key) in sortMap" :key="key" :value="key">{{item}}</option>
          </select>
          <button v-if="internalUser" class="btn btn-primary ms-4" style="width: 80px;" @click="scanOrder">Scan</button>
          <div class="col-1">
            <input type="text" class="form-control" placeholder="Scan Period" v-model="scanPeriod" />
          </div>
      </div>
      <div class="form-inline">
        <div class="input-group w-50 float-start">
          <span class="input-group-text fa fa-search"/>
          <input type="text" class="form-control" placeholder="Search by Account, Credit Card, Name" v-model="term" @input="getList">
        </div>
        <router-link class="btn btn-primary float-end me-4" style="width: 142px;" to="/account/new">New Account</router-link>
      </div>
    </CardTable>
</template>

<script>
import axios from 'axios'
import { decode } from "../util.js"

var getContent =  function(item, internalUser, creditCardList) {
  var content = (internalUser ? "<a class='text-decoration-none' target='#' href='/#/bestbuy?account=" + item.account + "'>" + item.account + "</a><div>" : item.account + "<div>");
  content += decode(item.phone) + "</div><div>" + decode(item.password) + "</div>";
  if (item.total) {
    content += "<div><div><span class='badge bg-" + (item.latestStatus === 'Canceled' ? "danger" :  (item.latestStatus ===  'Placed' ? "secondary" : "success")) + " me-1'>" + (item.latestStatus || "") + "</span><span class='badge bg-success me-1'>" + (item.total || "");
    content += "</span><span class='badge bg-danger me-1'>" + (item.failed || "") + "</span><span class='badge bg-success'>" + (item.total ? (parseFloat(item.failed) * 100 / parseFloat(item.total)).toFixed(2) + "%" : "");
    content += "</span></div><div><span class='badge bg-secondary'>" + (new Date(item.lastOrder).toLocaleString()) + "</span></div></div>"
  }
  content += "<div><span class='badge bg-secondary'>" + (new Date(item.createdDate).toLocaleString()) + "</span></div></div>"
  if (item.creditCard && item.creditCard.length > 0) {
    item.creditCard.forEach(currentCreditCard => {
      creditCardList.filter(obj => { 
        if(obj.creditcard === decode(currentCreditCard)) { 
          content += "<div><small class='text-success'>" + obj.name + "</small></div>";
          content += "<div><small class='text-muted fs-7'>" + obj.creditcard + "&nbsp;&nbsp;&nbsp;&nbsp;" + obj.month + "/" + obj.year + "&nbsp;&nbsp;&nbsp;&nbsp;" + obj.pin + "</small></div>";
        }
      });
    })
  }
  return content;
}

export default {
  name: 'CreditCard',
  mounted() {
    this.getList()
  },
  data() {
    return {
      tableBody: [],
      filteredTableBody: [],
      breadcrumbs: [{ name: "Account" }],
      sortMap: {"total,desc": "Total", "lastOrder,asc": "Latest Order", "latestStatus,asc": "Status", "failureRate,asc": "Failure Rate", "createdDate,asc": "Created Date"},
      retailerList: ["Amazon", "Best Buy", "Target", "Sams Club", "Sephora", "eBay", "Staples"],
      statusList: ["New", "Valid", "Unknown", "Blacklisted"],
      showModal: false,
      currentId: "",
      alertContent: "",
      alertClass: "alert-danger",
      term: "",
      merchantType: "Best Buy",
      status: "Valid",
      sort: "total,desc",
      loading: false,
      internalUser: localStorage.getItem("role") === "ROLE_ADMIN"
    }
  },
  methods: {
    openModal(id) {
      this.showModal = true;
      this.currentId = id;
    },
    confirmModal() {
      axios.delete("/account/" + this.currentId).then(() => {
        this.getList();
      }).catch(error => {
        this.alertContent = error.response ? error.response.data : error;
        this.alertClass = "alert-danger";
      })
      this.showModal = false;
    },
    getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      axios.get("/account?term=" + this.term + "&merchantType=" + this.merchantType + "&status=" + this.status + "&sort=" + this.sort + "&size=1000").then(response => {
        var openModal = this.openModal;
        var internalUser = this.internalUser;
        var creditCardList = response.data.creditCardList.map( item => {
          return {
            name: item.name,
            creditcard: decode(item.creditcard),
            month: item.month,
            year: item.year,
            pin: decode(item.pin)
          }
        })
        this.tableBody = response.data.accountList.map(item => {
          return {
            title: item.merchantType,
            content: getContent(item, internalUser, creditCardList),
            footer: [{ type: "link", link: "/account/edit?id=" + item.id, class: "btn btn-primary btn-sm fa fa-pencil" },
              { type: "button", click: function() { openModal(item.id) }, name: "<span class='fa fa-trash'></span>", class: "btn-danger" }]
          }
        })
        this.loading = false;
        this.filterList();
      }).catch(error => {
        this.alertContent = error.response ? error.response.data : error;
        this.loading = false;
      })
    },
    scanOrder() {
      axios.get("/bestbuy/scan?scanPeriod=" + this.scanPeriod).then((response) => {
          this.alertContent = response.data;
          this.alertClass = "alert-success";
      }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
      })
    },
    filterList() {
      if(this.term) {
        this.filteredTableBody = this.tableBody.filter((item) => item.content.toLowerCase().indexOf(this.term.toLowerCase()) > -1)
      } else {
        this.filteredTableBody = this.tableBody;
      }
    }
  }
}
</script>